import { MutationTree } from "vuex";
import { IAppState } from "./state";
import * as mutationTypes from "./mutations-types";
import { IAffiliate, IClient, ICompany, IField, IRequest, ISnakeApplication, IEntityRegion } from "@/models";

export const mutations: MutationTree<IAppState> = {

    [mutationTypes.SET_SIDEBAR]: (state, payload: boolean) => {
        state.sidebar = payload;
    },

    [mutationTypes.SET_AFFILIATES]: (state, payload: IAffiliate[]) => {
        state.affiliates = payload;
    },

    [mutationTypes.SET_COMPANIES]: (state, payload: ICompany[]) => {
        state.companies = payload;
    },

    [mutationTypes.SET_FIELDS]: (state, payload: IField[]) => {
        state.fields = payload;
    },

    [mutationTypes.SET_REQUESTS]: (state, payload: IRequest[]) => {
        state.requests = payload;
    },

    [mutationTypes.SET_USER_REQUESTS]: (state, payload: IRequest[]) => {
        state.userRequests = payload;
    },

    [mutationTypes.SET_APPROVER_REQUESTS]: (state, payload: IRequest[]) => {
        state.approverRequests = payload;
    },

    [mutationTypes.SET_USER_REQUESTS_SNAKE_APPS]: (state, payload: ISnakeApplication[]) => {
        state.userRequestsSnakeApps = payload;
    },

    [mutationTypes.ADD_REQUEST]: (state, payload: IRequest) => {
        state.userRequests.push(payload);
    },

    [mutationTypes.UPDATE_REQUEST]: (state, payload: IRequest) => {
        const existingIndex = state.userRequests.findIndex(request => request.id === payload.id);
        if (existingIndex === -1) {
            state.userRequests.push(payload);
        }
        else {
            state.userRequests[existingIndex] = payload;
            state.userRequests = [...state.userRequests];
        }
        state.currentRequest = payload;
    },

    [mutationTypes.APPROVE_REQUEST]: (state, payload: IRequest) => {
        state.currentRequest = payload;
    },

    [mutationTypes.REPLACE_FIELD]: (state, payload: IField) => {
        state.fields[state.fields.findIndex(f => f.id)] = payload;
    },

    [mutationTypes.SET_CURRENT_REQUEST]: (state, payload: IRequest) => {
        state.currentRequest = payload;
    },

    [mutationTypes.SET_CURRENT_SNAKE_APP]: (state, payload: ISnakeApplication) => {
        state.currentSnakeApp = payload;
    },

    [mutationTypes.SET_ITEMS_PER_PAGE]: (state, payload: number) => {
        state.itemsPerPage = payload;
    },

    [mutationTypes.SET_CLIENTS]: (state, payload: IClient[]) => {
        state.clients = payload;
    },

    [mutationTypes.SET_CURRENT_CLIENT]: (state, payload: IClient) => {
        state.currentClient = payload;
    },

    [mutationTypes.CHANGE_STATUS]: (state, payload: IClient) => {
        state.currentClient = payload;
    },

    [mutationTypes.SET_SNAKE_GROUPED_ENTITIES]: (state, payload: IEntityRegion[]) => {
        state.snakeAppGroupedEntities = payload;
    },
};
