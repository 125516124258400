import { IDepartment } from "@/models";
import { BaseRepository } from "@/repositories";

class DepartmentRepository extends BaseRepository<IDepartment> {
    constructor() {
        super("/departments");
    }

    public async search(payload: Object): Promise<IDepartment[]> {
        return await this.post<IDepartment[]>(`/search`, payload);
    }

    public async getAllWithRequestsNumber(url?: string): Promise<IDepartment[]> {
        return await this.get<IDepartment[]>(url);
    }

}

const departmentRepository = new DepartmentRepository();

export { DepartmentRepository, departmentRepository };