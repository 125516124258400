import { IAffiliate, IClient, ICompany, IField, IRequest, ISnakeApplication, IEntityRegion } from "@/models";

interface IAppState {
    affiliates: IAffiliate[];
    companies: ICompany[];
    fields: IField[];
    requests: IRequest[];
    sidebar: boolean;
    userRequests: IRequest[];
    approverRequests: IRequest[];
    userRequestsSnakeApps: ISnakeApplication[];
    currentRequest: IRequest;
    currentSnakeApp: ISnakeApplication;
    itemsPerPage: number;
    currentClient: IClient;
    clients: IClient[];
    snakeAppGroupedEntities: IEntityRegion[];
}

const initialState: IAppState = {
    affiliates: [],
    fields: [],
    companies: [],
    requests: [],
    sidebar: true,
    userRequests: [],
    approverRequests: [],
    userRequestsSnakeApps: [],
    currentRequest: null,
    currentSnakeApp: null,
    itemsPerPage: 10,
    currentClient: null,
    clients: [],
    snakeAppGroupedEntities: []
};

export { IAppState, initialState };
