import { ISnakeApplication } from "@/models";

import { BaseRepository } from "@/repositories";

class SnakeApplicationRepository extends BaseRepository<ISnakeApplication> {
    constructor() {
        super("/applications");
    }
}

const snakeApplicationRepository = new SnakeApplicationRepository();

export { SnakeApplicationRepository, snakeApplicationRepository };
