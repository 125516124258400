import { IField, IFieldForm, IFieldNbRequests } from "@/models";
import { BaseRepository } from "@/repositories";

class FieldRepository extends BaseRepository<IField> {
    constructor() {
        super("/fields");
    }

    public async replace(data: IFieldForm, url?: string, type?: string): Promise<IField | IField[]> {
        return await this.put<IField | IField[]>(`/${data.id}`, data, type);
    }

    public async search(payload: Object): Promise<IFieldNbRequests[]> {
        return await this.post<IFieldNbRequests[]>(`/search`, payload);
    }

    public async getAllWithRequestsNumber(url?: string): Promise<IFieldNbRequests[]> {
        return await this.get<IFieldNbRequests[]>(url);
    }

    public async deleteField(id: string): Promise<number>{
        return await this.delete<number>(`/${id}`);
    }
}

const fieldRepository = new FieldRepository();

export { FieldRepository, fieldRepository };
