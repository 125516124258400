import { IAffiliate, IClient, ICompany, IFieldForm, IRequest, IRequestApprovalForm, IRequestForm, ISnakeApplication, ISnakeApplicationEntity, IEntityRegion} from "@/models";
import { IClientForm } from "@/models/IClientForm";
import { affiliateRepository, companyRepository, fieldRepository, requestRepository, clientRepository } from "@/repositories";
import { snakeClient } from "@/services/SnakeClient";
import { ActionTree } from "vuex";
import * as mutations from "./mutations-types";
import { IAppState } from "./state";

export const actions: ActionTree<IAppState, any> = {

    updateSidebar: async (context, payload: boolean): Promise<void> => {
        context.commit(mutations.SET_SIDEBAR, payload);
    },

    loadAffiliates: async (context, payload: string[]): Promise<IAffiliate[]> => await affiliateRepository.search(payload),

    loadCompanies: async (context, payload: string[]): Promise<ICompany[]> => await companyRepository.search(payload),

    loadFields: async (context): Promise<void> => {
        const fields = await fieldRepository.getAll();
        context.commit(mutations.SET_FIELDS, fields);
    },

    loadRequests: async (context): Promise<void> => {
        const requests = await requestRepository.getAll();
        context.commit(mutations.SET_REQUESTS, requests);
    },

    loadRequestById: async (context, requestId: string): Promise<void> => {
        const request = await requestRepository.getOne(requestId);
        context.commit(mutations.SET_CURRENT_REQUEST, request);
    },

    loadClients: async (context): Promise<IClient[]> => {
        const clients = await clientRepository.getAll();
        context.commit(mutations.SET_CLIENTS, clients);
        return clients;
    },

    loadClientById: async (context, clientId: string): Promise<void> => {
        const client = await clientRepository.getOne(clientId);
        context.commit(mutations.SET_CURRENT_CLIENT, client);
    },

    loadUserRequests: async (): Promise<IRequest[]> => await requestRepository.getUserRequests(),

    loadApproverRequests: async (context, includeClosedRejected): Promise<IRequest[]> => await requestRepository.getApproverRequests(includeClosedRejected),

    loadSnakeApps: async (context, payload: string[]): Promise<ISnakeApplication[]> => await snakeClient.getApplicationsByIds(payload),

    loadSnakeAppsByOwner: async (context, payload: string): Promise<ISnakeApplication[]> => await snakeClient.getApplicationsByOwner(payload),

    loadSnakeGroupedEntities: async (context, entities: ISnakeApplicationEntity[]): Promise<void> => {
        let groupedEntities: IEntityRegion[] = [];
        if(entities != null){
            entities = entities.sort((a, b) => (a.region > b.region) ? 1 : -1);
            entities.forEach(entity =>{
                if(groupedEntities.length == 0 || groupedEntities[groupedEntities.length - 1].region !== entity.region){
                    //!- First element in the list OR We jump to the next *entity* region
                    const e = { region: entity.region, entities: [ entity ] };
                    groupedEntities.push(e);
                }
                else{
                    //!- Current regions is the same as *entity* region
                    groupedEntities[groupedEntities.length - 1].entities.push(entity);
                }
            });
        }
        context.commit(mutations.SET_SNAKE_GROUPED_ENTITIES, groupedEntities);
    },

    loadSnakeAppById: async (context, snakeId: string): Promise<void> => {
        const snakeApp = await snakeClient.getApplicationById(snakeId);
        context.commit(mutations.SET_CURRENT_SNAKE_APP, snakeApp);
    },

    addRequest: async (context, payload: IRequestForm): Promise<void> => {
        const request = await requestRepository.add(payload);
        context.commit(mutations.ADD_REQUEST, request);
    },

    updateRequest: async (context, payload: IRequest): Promise<void> => {
        const request = await requestRepository.replace(payload, `/${payload.id}`);
        context.commit(mutations.UPDATE_REQUEST, request);
    },

    approveRequest: async (context, payload: IRequestApprovalForm): Promise<void> => {
        const request = await requestRepository.update(payload, `/${payload.requestId}`);
        context.commit(mutations.APPROVE_REQUEST, request);
    },

    updateField: async (context, payload: IFieldForm): Promise<void> => {
        const request = await fieldRepository.replace(payload);
        context.commit(mutations.REPLACE_FIELD, request);
    },

    updateItemsPerPage: (context, payload: number): void => {
        context.commit(mutations.SET_ITEMS_PER_PAGE, payload);
    },

    changeStatus: async (context, payload: IClientForm): Promise<void> => {
        const client = await clientRepository.update(payload, `/${payload.id}`);
        context.commit(mutations.CHANGE_STATUS, client);
    },

};
