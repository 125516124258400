import { ISupervisoryOrganization } from "@/models";
import { BaseRepository } from "@/repositories";

class SupervisoryOrganizationRepository extends BaseRepository<ISupervisoryOrganization> {
    constructor() {
        super("/supervisoryorganizations");
    }

    public async search(payload: Object): Promise<ISupervisoryOrganization[]> {
        return await this.post<ISupervisoryOrganization[]>(`/search`, payload);
    }

    public async getAllWithRequestsNumber(url?: string): Promise<ISupervisoryOrganization[]> {
        return await this.get<ISupervisoryOrganization[]>(url);
    }
}

const supervisoryOrganizationRepository = new SupervisoryOrganizationRepository();

export { SupervisoryOrganizationRepository, supervisoryOrganizationRepository };
