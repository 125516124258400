import { ActionTree } from "vuex";
import { msalInstance } from "@/services";
import { LOAD_ACCOUNT, LOGOUT, SET_PHOTO, SET_USER, SET_USER_GRAPH } from "./mutations-types";
import { IAccountState } from "./state";
import { graphClient } from "@/services/GraphClient";
import { userRepository } from "@/repositories";

export const actions: ActionTree<IAccountState, any> = {
    loadAccount: (context): void => {
        const account = msalInstance.getActiveAccount();
        context.commit(LOAD_ACCOUNT, account);
    },

    logout: async (context): Promise<void> => {
        msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
        context.commit(LOGOUT);
    },

    loadUser: async (context): Promise<void> => {
        const user = await userRepository.getOne("me");
        context.commit(SET_USER, user);
    },

    loadUserGraph: async (context): Promise<void> => {
        const userGraph = await graphClient.getCurrentUser();
        context.commit(SET_USER_GRAPH, userGraph);
    },

    loadUserPhoto: async (context): Promise<void> => {
        const photo = await graphClient.getCurrentUserPhoto();
        context.commit(SET_PHOTO, photo);
    },
};
