import * as msal from "@azure/msal-browser";
import { MutationTree } from "vuex";
import { IAccountState } from "./state";
import * as mutationTypes from "./mutations-types";
import { IUser, IUserGraph } from "@/models";

export const mutations: MutationTree<IAccountState> = {
    [mutationTypes.LOAD_ACCOUNT]: (state, payload: msal.AccountInfo) => {
        state.msalAccount = payload;
    },

    [mutationTypes.LOGOUT]: (state) => {
        state.msalAccount = null;
    },

    [mutationTypes.SET_USER]: (state, payload: IUser) => {
        state.user = payload;
    },

    [mutationTypes.SET_USER_GRAPH]: (state, payload: IUserGraph) => {
        state.userGraph = payload;
    },

    [mutationTypes.SET_PHOTO]: (state, payload: string) => {
        state.userPhoto = payload;
    },
};
