import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#3b7ddd",
                secondary: "#ed1c24",
                accent: "#fcc028",
                primeGreen: "#8bc53e",
                primeBlue: "#4fa8de",
                primeRed: "#da281c",
                primeYellow: "#e6ac0e",
                primePink: "#e93964",
                primeNeutral: "#1e4b72",
            },
        },
        options: {
            customProperties: true,
        },
    },
    icons: {
        iconfont: "mdi",
    },
});
