const REPLACE_FIELD = "REPLACE_FIELD";
const ADD_REQUEST = "ADD_REQUEST";
const UPDATE_REQUEST = "UPDATE_REQUEST";
const APPROVE_REQUEST = "APPROVE_REQUEST";
const SET_AFFILIATES = "SET_AFFILIATES";
const SET_COMPANIES = "SET_COMPANIES";
const SET_FIELDS = "SET_FIELDS";
const SET_SIDEBAR = "SET_SIDEBAR";
const SET_REQUESTS = "SET_REQUESTS";
const SET_USER_REQUESTS = "SET_USER_REQUESTS";
const SET_APPROVER_REQUESTS = "SET_APPROVER_REQUESTS";
const SET_USER_REQUESTS_SNAKE_APPS = "SET_USER_REQUESTS_SNAKE_APPS";
const SET_CURRENT_REQUEST = "SET_CURRENT_REQUEST";
const SET_CURRENT_SNAKE_APP = "SET_CURRENT_SNAKE_APP";
const SET_ITEMS_PER_PAGE = "SET_ITEMS_PER_PAGE";
const SET_CLIENTS = "SET_CLIENTS";
const SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT";
const CHANGE_STATUS = "CHANGE_STATUS";
const SET_SNAKE_GROUPED_ENTITIES = "SET_SNAKE_GROUPED_ENTITIES";

export {
    REPLACE_FIELD,
    ADD_REQUEST,
    UPDATE_REQUEST,
    APPROVE_REQUEST,
    SET_AFFILIATES,
    SET_COMPANIES,
    SET_FIELDS,
    SET_REQUESTS,
    SET_SIDEBAR,
    SET_USER_REQUESTS,
    SET_APPROVER_REQUESTS,
    SET_USER_REQUESTS_SNAKE_APPS,
    SET_CURRENT_REQUEST,
    SET_CURRENT_SNAKE_APP,
    SET_ITEMS_PER_PAGE,
    SET_CLIENTS,
    SET_CURRENT_CLIENT,
    CHANGE_STATUS,
    SET_SNAKE_GROUPED_ENTITIES
};
