import { IAffiliate, IClient, ICompany, IField, IRequest, ISnakeApplication, IEntityRegion } from "@/models";
import { GetterTree } from "vuex";
import { IAppState } from "./state";

const getters: GetterTree<IAppState, any> = {
    // roles: (state): IRole[] => state.roles,
    affiliates: (state): IAffiliate[] => state.affiliates,
    companies: (state): ICompany[] => state.companies,
    sidebar: (state): boolean => state.sidebar,
    requests: (state): IRequest[] => state.requests,
    userRequests: (state): IRequest[] => state.userRequests,
    approverRequests: (state): IRequest[] => state.approverRequests,
    fields: (state): IField[] => state.fields,
    userRequestsSnakeApps: (state): ISnakeApplication[] => state.userRequestsSnakeApps,
    currentRequest: (state): IRequest => state.currentRequest,
    currentSnakeApp: (state): ISnakeApplication => state.currentSnakeApp,
    itemsPerPage: (state): number => state.itemsPerPage,
    clients: (state): IClient[] => state.clients,
    currentClient: (state): IClient => state.currentClient,
    snakeGroupedEntities: (state): IEntityRegion[] => state.snakeAppGroupedEntities,
};

export { getters };
