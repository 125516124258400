import { IRequest, IRequestDatatable } from "@/models";
import { BaseRepository } from "@/repositories";

class RequestRepository extends BaseRepository<IRequest> {
    constructor() {
        super("/requests");
    }

    public getUserRequests(): Promise<IRequest[]> {
        const path = "/requestor/";
        return this.getAll({ }, path);
    }

    public getApproverRequests(includeClosedRejected: boolean): Promise<IRequest[]> {
        const path = "/approver/";
        return this.getAll({ includeClosedRejected }, path);
    }
    
    public async getActiveRequestsByField(fieldId: string): Promise<IRequestDatatable[]> {
        return await this.get<IRequestDatatable[]>(`/field/active/${fieldId}`);
    }

    public async getClosedRequestsByField(fieldId: string): Promise<IRequestDatatable[]> {
        return await this.get<IRequestDatatable[]>(`/field/closed/${fieldId}`);
    }
}

const requestRepository = new RequestRepository();

export { RequestRepository, requestRepository };
