import { IEmployeeType } from "@/models";
import { BaseRepository } from "@/repositories";

class EmployeeTypeRepository extends BaseRepository<IEmployeeType> {
    constructor() {
        super("/employeetypes");
    }

    public async search(payload: Object): Promise<IEmployeeType[]> {
        return await this.post<IEmployeeType[]>(`/search`, payload);
    }

    public async getAllWithRequestsNumber(url?: string): Promise<IEmployeeType[]> {
        return await this.get<IEmployeeType[]>(url);
    }
}

const employeeTypeRepository = new EmployeeTypeRepository();

export { EmployeeTypeRepository, employeeTypeRepository };

