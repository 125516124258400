import { IAffiliate } from "@/models";
import { BaseRepository } from "@/repositories";

class AffiliateRepository extends BaseRepository<IAffiliate> {
    constructor() {
        super("/affiliates");
    }

    public async search(payload: Object): Promise<IAffiliate[]> {
        return await this.post<IAffiliate[]>(`/search`, payload);
    }

    public async getAllWithRequestsNumber(url?: string): Promise<IAffiliate[]> {
        return await this.get<IAffiliate[]>(url);
    }
}

const affiliateRepository = new AffiliateRepository();

export { AffiliateRepository, affiliateRepository };
