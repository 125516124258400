import { NavigationGuard } from "vue-router";
import { store } from "@/store";
import { Roles } from "@/enums";
import { RouteNames } from ".";

export const roleGuard: NavigationGuard = async (to, from, next) => {
    // If user is not yet authenticated, let auth guard handle login.
    if (!store.getters["account/isAuthenticated"]) {
        return next();
    }

    // Get route role requirements

    // Search a matched route containing requirement details
    // starting from the last item in the array, which is the most specific
    let matchingRoute = null;
    for (let i = to.matched.length - 1; i >= 0; i--) {
        const route = to.matched[i];
        if (route.meta?.requiredRoles != null) {
            matchingRoute = to.matched[i];
            break;
        }
    }

    const requiredRoles: Roles[] = matchingRoute?.meta.requiredRoles ?? [];

    // If routes requires no role, continue
    if (requiredRoles.length === 0) {
        return next();
    }

    // If all else fails, user is redirect to Unauthorized page
    return next({ name: RouteNames.unauthorized });
};
