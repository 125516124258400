import Vue from "vue";
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize,
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, {
        ...validation,
    });
}

extend("required_relation", {
    validate: (value: any) => value && value > 0,
    message: field => `The ${field} field cannot be empty.`,
});

extend("at_least_one", {
    validate: (value) => {
        const values = Array.from(value);
        return values.length >= 1;
    },
    message: field => `You have to select at least one ${field}`,
    computesRequired: true,
});

localize({
    en,
});
localize("en");
