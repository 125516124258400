import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { authGuard } from "./AuthGuard";
import { roleGuard } from "./RoleGuard";
import { RouteNames } from "./RouteNames";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: "/",
        component: () => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "/",
                component: () => import(/* webpackChunkName: "home" */ "../views/home/Home.vue"),
            },
            {
                path: "/dashboard",
                name: RouteNames.home,
                component: () => import(/* webpackChunkName: "home" */ "../views/home/Home.vue"),
            },
        ]
    },
    {
        path: "/requests",
        component: () => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "/request/new",
                name: RouteNames.requestCreate,
                component: () =>
                    import(
                        /* webpackChunkName: "requestCreate" */ "@/views/request/form/RequestForm.vue"
                    ),
            },
            {
                path: "/requests/:id/edit",
                name: RouteNames.requestUpdate,
                component: () =>
                    import(
                        /* webpackChunkName: "requestUpdate" */ "@/views/request/form/RequestForm.vue"
                    ),
            },
            {
                path: "/requests",
                name: RouteNames.myRequests,
                component: () =>
                    import(
                        /* webpackChunkName: "requestUpdate" */ "@/views/requests/user/RequestsUser.vue"
                    ),
            },
            {
                path: "/requests/:id",
                name: RouteNames.requestView,
                component: () =>
                    import(
                        /* webpackChunkName: "requestView" */ "@/views/request/view/RequestView.vue"
                    ),
            },
        ]
    },
    {
        path: "/clients",
        component: () => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children: [
            {
                path: "/clients/:id",
                name: RouteNames.clientView,
                component: () =>
                    import(
                        /* webpackChunkName: "clientView" */ "@/views/client/view/ClientView.vue"
                    ),
            },
        ]
    },
    {
        path: "/admin",
        component: () => import(/* webpackChunkName: "layout" */ "@/views/layout/Layout.vue"),
        children:[
            {
                path: "/admin/fields",
                name: RouteNames.fieldsList,
                component: () =>
                    import(
                        /* webpackChunkName: "fieldsList" */ "@/views/fields/list/FieldsList.vue"
                    ),
            },
            {
                path: "/admin/fields/:id/edit",
                name: RouteNames.fieldEdit,
                component: () =>
                    import(
                        /* webpackChunkName: "fieldEdit" */ "@/views/fields/edit/FieldEdit.vue"
                    ),
            },
            {
                path: "/admin/requests",
                name: RouteNames.manageRequests,
                component: () =>
                    import(
                        /* webpackChunkName: "requestsManage" */ "@/views/requests/manage/RequestsManage.vue"
                    ),
            },
            {
                path: "/admin/clients",
                name: RouteNames.manageClients,
                component: () =>
                    import(
                        /* webpackChunkName: "clientsManage" */ "@/views/clients/manage/ClientsManage.vue"
                    ),
            },
            {
                path: "/admin/statistics",
                name: RouteNames.statistics,
                component: () =>
                    import(
                        /* webpackChunkName: "requestsManage" */ "@/views/statistics/Statistics.vue"
                    ),
            },
            {
                path: "/admin/references/affiliate",
                name: RouteNames.affiliate,
                component: () =>
                    import(
                        /* webpackChunkName: "requestsManage" */ "@/views/references/affiliate/Affiliate.vue"
                    ),
            },
            {
                path: "/admin/references/company",
                name: RouteNames.company,
                component: () =>
                    import(
                        /* webpackChunkName: "requestsManage" */ "@/views/references/company/Company.vue"
                    ),
            },
            {
                path: "/admin/references/department",
                name: RouteNames.department,
                component: () =>
                    import(
                        /* webpackChunkName: "requestsManage" */ "@/views/references/department/Department.vue"
                    ),
            },
            {
                path: "/admin/references/employeeType",
                name: RouteNames.employeeType,
                component: () =>
                    import(
                        /* webpackChunkName: "requestsManage" */ "@/views/references/employeeType/EmployeeType.vue"
                    ),
            },
            {
                path: "/admin/references/location",
                name: RouteNames.location,
                component: () =>
                    import(
                        /* webpackChunkName: "requestsManage" */ "@/views/references/location/Location.vue"
                    ),
            },
            {
                path: "/admin/references/paygroup",
                name: RouteNames.payGroup,
                component: () =>
                    import(
                        /* webpackChunkName: "requestsManage" */ "@/views/references/payGroup/PayGroup.vue"
                    ),
            },
            {
                path: "/admin/references/supervisoryOrganization",
                name: RouteNames.supervisoryOrganization,
                component: () =>
                    import(
                        /* webpackChunkName: "requestsManage" */ "@/views/references/supervisoryOrganization/SupervisoryOrganization.vue"
                    ),
            },
            {
                path: "/admin/references/userGroup",
                name: RouteNames.userGroup,
                component: () =>
                    import(
                        /* webpackChunkName: "requestsManage" */ "@/views/references/userGroup/UserGroup.vue"
                    ),
            },
            {
                path: "/admin/references/workerType",
                name: RouteNames.workerType,
                component: () =>
                    import(
                        /* webpackChunkName: "requestsManage" */ "@/views/references/workerType/WorkerType.vue"
                    ),
            },
        ],
    },
    {
        path: "/auth",
        redirect: "/",
    },
    {
        path: "/unauthorized",
        name: RouteNames.unauthorized,
        component: () => import(/* webpackChunkName: "unauthorized" */ "@/views/unauthorized/Unauthorized.vue"),
    },
    {
        path: "/*",
        name: RouteNames.notFound,
        component: () => import(/* webpackChunkName: "notFound" */ "@/views/notFound/NotFound.vue"),
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});

router.beforeEach(authGuard);
router.beforeEach(roleGuard);

export { router, RouteNames };
