import { IWorkDayPeopleResult, IClientPreviewSearchFilter } from "@/models";
import { BaseRepository } from "@/repositories";

class WorkDayPeopleRepository extends BaseRepository<IWorkDayPeopleResult> {
    constructor() {
        super("/workdaypeople");
    }

    public async getAllPeople(clientId: string, cltSecret: string, fltr: IClientPreviewSearchFilter): Promise<IWorkDayPeopleResult> {
        return await this.post<IWorkDayPeopleResult>(`/${clientId}/preview/`, {
            clientSecret: cltSecret,
            filter: fltr
        });
    }

    public async getNextPeople(clientId: string, cltSecret: string, link: string): Promise<IWorkDayPeopleResult> {
        return await this.post<IWorkDayPeopleResult>(`/${clientId}/preview/next/`, {
            clientSecret: cltSecret,
            oDataNextLink: link,
            filter: null
        });
    }
}

const workDayPeopleRepository = new WorkDayPeopleRepository();

export { WorkDayPeopleRepository, workDayPeopleRepository };
