export enum RequestStatuses {
    Rejected = "Rejected",
    AwaitingNewFieldsApproval = "AwaitingNewFieldsApproval",
    AwaitingGreenApproval = "AwaitingGreenApproval",
    AwaitingOrangeApproval = "AwaitingOrangeApproval",
    AwaitingRedApproval = "AwaitingRedApproval",
    AwaitingAadAdminsApproval = "AwaitingAadAdminsApproval",
    Closed = "Closed",
    Enabled = "Enabled",
    Disabled = "Disabled",
}
