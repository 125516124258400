import { BaseClient } from "@/services";

export abstract class BaseRepository<T> extends BaseClient {
    protected constructor(url: string) {
        super(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async getAll(parameters?: any, url?: string): Promise<T[]> {
        return await this.get<T[]>(url, parameters);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async getOne(key: string | number, parameters?: any): Promise<T> {
        return await this.get<T>(`/${key}`, parameters);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async add(data: any): Promise<T> {
        return await this.post<T>("/", data);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async update(data: any, url?: string): Promise<T> {
        return await this.patch<T>(url, data);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public async replace(data: any, url?: string, type?: string): Promise<T | T[]> {
        return await this.put<T | T[]>(url, data, type);
    }

    public async remove(id: number): Promise<T[]> {
        return await this.delete(`/${id}`);
    }

    public async export(url?: string): Promise<any>{
        return await this.downloadFile(url);
    } 
}
