import { GetterTree } from "vuex";
import { IAccountState } from "./state";
import { IUser } from "@/models";

const getters: GetterTree<IAccountState, any> = {
    isAuthenticated: (state): boolean => !(!state.msalAccount),
    user: (state): IUser | null => state.user,
    userEmail: (state): string => (state.msalAccount?.idTokenClaims as any).email,
    userName: (state): string => (state.msalAccount?.idTokenClaims as any).name,
    userPhoto: (state): string => state.userPhoto ? state.userPhoto : "",
    jobTitle: (state): string => state.userGraph ? state.userGraph.jobTitle : "",
};

export { getters };
