import { IUser } from "@/models";

import { BaseRepository } from "@/repositories";

class UserRepository extends BaseRepository<IUser> {
    constructor() {
        super("/users");
    }
}

const userRepository = new UserRepository();

export { UserRepository, userRepository };
