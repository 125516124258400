export enum FileNames {
    Affiliate = "Export_Affiliate.xlsx",
    Company = "Export_Company.xlsx",
    Departement = "Export_Departement.xlsx",
    EmployeeType = "Export_Employee_Type.xlsx",
    Location = "Export_Location.xlsx",
    PayGroup = "Export_Pay_Group.xlsx",
    SupervisoryOrganization = "Export_Supervisory_Organization.xlsx",
    UserGroup = "Export_User_Group.xlsx",
    WorkerType = "Export_Worker_Type.xlsx",
}