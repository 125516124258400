const LOAD_ACCOUNT = "LOAD_ACCOUNT";
const LOGOUT = "LOGOUT";
const SET_PHOTO = "SET_PHOTO";
const SET_USER = "SET_USER";
const SET_USER_GRAPH = "SET_USER_GRAPH";

export {
    LOAD_ACCOUNT,
    LOGOUT,
    SET_PHOTO,
    SET_USER,
    SET_USER_GRAPH,
};
