import { NavigationGuard } from "vue-router";
import { WaitKeys } from "@/helpers/WaitKeys";
import { RouteNames } from "@/router";
import { msalInstance } from "@/services";
import { store } from "@/store";

export const authGuard: NavigationGuard = async (to, from, next) => {
    store.dispatch("wait/start", WaitKeys.login);

    // Login with Azure AD
    const loggedIn = await msalInstance.login();
    if (!loggedIn) {
        return next({ name: RouteNames.unauthorized });
    }

    // Load user account
    await store.dispatch("account/loadAccount");
    if (!store.getters["account/isAuthenticated"]) {
        return next({ name: RouteNames.unauthorized });
    }

    store.dispatch("wait/end", WaitKeys.login);

    return next();
};