import { ILocation } from "@/models";
import { BaseRepository } from "@/repositories";

class LocationRepository extends BaseRepository<ILocation> {
    constructor() {
        super("/locations");
    }

    public async search(payload: Object): Promise<ILocation[]> {
        return await this.post<ILocation[]>(`/search`, payload);
    }

    public async getAllWithRequestsNumber(url?: string): Promise<ILocation[]> {
        return await this.get<ILocation[]>(url);
    }
}

const locationRepository = new LocationRepository();

export { LocationRepository, locationRepository };
