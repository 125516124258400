export class RouteNames {
    public static readonly home = "Home";

    public static readonly unauthorized = "unauthorized";

    public static readonly notFound = "not-found";

    public static readonly requestView = "Request";

    public static readonly requestCreate = "RequestCreate";

    public static readonly requestUpdate = "RequestUpdate";

    public static readonly fieldsList = "FieldsList";

    public static readonly fieldEdit = "FieldEdit";

    public static readonly myRequests = "MyRequests";

    public static readonly manageRequests = "ManageRequests";

    public static readonly statistics = "Statistics";

    public static readonly clientView = "Client";

    public static readonly manageClients = "ManageClients";

    public static readonly affiliate = "Affiliate";

    public static readonly company = "Company";

    public static readonly department = "Department";

    public static readonly employeeType = "EmployeeType";

    public static readonly location = "Location";

    public static readonly payGroup = "PayGroup";

    public static readonly supervisoryOrganization = "SupervisoryOrganization";

    public static readonly userGroup = "UserGroup";

    public static readonly workerType = "WorkerType";
}
