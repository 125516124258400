import { IWorkerType } from "@/models";
import { BaseRepository } from "@/repositories";

class WorkerTypeRepository extends BaseRepository<IWorkerType> {
    constructor() {
        super("/workertypes");
    }

    public async search(payload: Object): Promise<IWorkerType[]> {
        return await this.post<IWorkerType[]>(`/search`, payload);
    }

    public async getAllWithRequestsNumber(url?: string): Promise<IWorkerType[]> {
        return await this.get<IWorkerType[]>(url);
    }
}

const workerTypeRepository = new WorkerTypeRepository();

export { WorkerTypeRepository, workerTypeRepository };
