import { IClient, IClientDatatable } from "@/models";

import { BaseRepository } from "@/repositories";

class ClientRepository extends BaseRepository<IClient> {
    constructor() {
        super("/clients");
    }

    public async getClientsByField(fieldId: string): Promise<IClientDatatable[]> {
        return await this.get<IClientDatatable[]>(`/field/${fieldId}`);
    }
}

const clientRepository = new ClientRepository();

export { ClientRepository, clientRepository };
