import axios, { AxiosInstance, AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { settings } from "@/settings";
import { scopes, graphScopes, snakeScopes, msalInstance } from "./MsalService";
import { ClientTypes } from "@/enums";

export class BaseClient {

    protected apiClient: AxiosInstance;

    protected constructor(url: string, clientType: ClientTypes = ClientTypes.WebApi) {

        const baseUrl = this.getBaseUrl(url, clientType);

        this.apiClient = axios.create({
            baseURL: baseUrl,
            responseType: "json",
        });

        this.apiClient.interceptors.request.use(this.requestInterceptor);
        this.apiClient.interceptors.response.use(undefined, this.blobErrorResponseInterceptor);
        this.apiClient.interceptors.response.use(undefined, this.errorResponseInterceptor);
    }

    protected getBaseUrl(url: string, clientType: ClientTypes): string{
        switch(clientType){
            case ClientTypes.WebApi: {
                return `${settings.webApi.baseUrl}${url}`;
            }

            case ClientTypes.GraphApi: {
                return `${settings.graphApi.baseUrl}${url}`;
            }

            case ClientTypes.SnakeApi: {
                return `${settings.snakeApi.baseUrl}${url}`;
            }
        }
    }

    protected async get<T>(service: string, parameters?: any): Promise<T | null> {
        try {
            const response = await this.apiClient.get<T>(service, {
                params: parameters ?? {},
            });

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            return Promise.reject(error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected async post<T>(service: string, data?: any): Promise<T | null> {
        try {
            const response = await this.apiClient.post<T>(service, data, {  });

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            return Promise.reject(error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected async patch<T>(service: string, data?: any): Promise<T | null> {
        try {
            const response = await this.apiClient.patch<T>(service, data, {});

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            return Promise.reject(error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected async put<T>(service: string, data?: any, type?: string): Promise<T | null> {
        try {
            const response = await this.apiClient.put<T>(service, data, {
                headers: {
                    "Content-Type": type ?? "application/json",
                },
            });

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            return Promise.reject(error);
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    protected async delete<T>(service: string): Promise<T | null> {
        try {
            const response = await this.apiClient.delete<T>(service);

            if (response.status >= 200 && response.status < 300) {
                return response.data;
            }
            else {
                throw response;
            }
        }
        catch (error) {
            return Promise.reject(error);
        }
    }

    protected async downloadPhoto(service: string): Promise<AxiosResponse> {
        try {
            return await this.apiClient.get<string>(service, { responseType: "arraybuffer", timeout: 60_000 });
        }
        catch (error) {
            return Promise.reject(error);
        }
    }

    protected async downloadFile(service: string): Promise<AxiosResponse> {
        try {
            return await this.apiClient.get<Blob>(service, { responseType: "blob", timeout: 60_000 });
        }
        catch (error) {
            return Promise.reject(error);
        }
    }

    protected isAxiosError(error: Error): error is AxiosError {
        return (error as AxiosError).isAxiosError;
    }

    private async requestInterceptor(request: AxiosRequestConfig) {
        const requestScopes = request.baseURL?.includes(settings.graphApi.baseUrl) ? graphScopes :
            (request.baseURL?.includes(settings.snakeApi.baseUrl) ? snakeScopes : scopes);
        const accessToken = await msalInstance.getAccessToken(requestScopes);

        // Add Authorization token
        if (accessToken != null) {
            request.headers.Authorization = `Bearer ${accessToken}`;
        }

        return Promise.resolve(request);
    }

    private blobErrorResponseInterceptor(error: AxiosError) {
        const contentType: string = error.response.headers["content-type"]?.toLowerCase();
        if (error.request.responseType === "blob" && contentType.includes("json")) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onload = () => {
                    error.response.data = JSON.parse(reader.result as string);
                    resolve(Promise.reject(error));
                };

                reader.onerror = () => reject(error);

                reader.readAsText(error.response.data);
            });
        }

        return Promise.reject(error);
    }

    private async errorResponseInterceptor(error: AxiosError) {
        // If the user is unauthenticated, retry login
        if (error.response?.status === 401) {
            await msalInstance.getAccessToken(scopes);
        }

        return Promise.reject(error);
    }
}
