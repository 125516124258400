import Vue from "vue";
import VueWait from "vue-wait";
import VueMeta from "vue-meta";
import { vueAppInsights, vuetify } from "@/plugins";

import { router } from "@/router";
import { store } from "@/store";

import App from "./App.vue";
import "./plugins/vee-validate";
import "./plugins/notifications";

// Import hooks for vue-class-component
import "vue-class-component/hooks";
import "@/router/registerHooks";

// Import global styles
import "@/assets/styles/global.scss";

Vue.config.productionTip = false;
Vue.config.performance = process.env.NODE_ENV !== "production";

// Setup App Insights
vueAppInsights();

Vue.use(VueMeta);
Vue.use(VueWait);

new Vue({
    router,
    store,
    wait: new VueWait({ useVuex: true }),
    vuetify,
    render: h => h(App),
}).$mount("#app");
