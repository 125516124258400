import { ICompany } from "@/models";
import { BaseRepository } from "@/repositories";

class CompanyRepository extends BaseRepository<ICompany> {
    constructor() {
        super("/companies");
    }

    public async search(payload: Object): Promise<ICompany[]> {
        return await this.post<ICompany[]>(`/search`, payload);
    }

    public async getAllWithRequestsNumber(url?: string): Promise<ICompany[]> {
        return await this.get<ICompany[]>(url);
    }

}

const companyRepository = new CompanyRepository();

export { CompanyRepository, companyRepository };
