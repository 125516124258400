import { IUser, IUserGraph } from "@/models";
import * as msal from "@azure/msal-browser";

interface IAccountState {
    msalAccount: msal.AccountInfo | null;
    user: IUser | null;
    userGraph: IUserGraph | null;
    userPhoto: string | null;
}

const initialState: IAccountState = {
    msalAccount: null,
    user: null,
    userGraph: null,
    userPhoto: null,
};

export { IAccountState, initialState };
