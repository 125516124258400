import { ClientTypes } from "@/enums";
import { ISnakeApplication, ISnakeSearchApplications } from "@/models";
import { BaseClient } from "./BaseClient";

class SnakeClient extends BaseClient {

    public constructor() {
        super("applications/", ClientTypes.SnakeApi);
    }

    public async getFilteredApplications(val: string): Promise<ISnakeApplication[]> {
        try {
            const response = await this.post<ISnakeSearchApplications>("search", {
                filters: {
                    searchString: val,
                },
                fields: [
                    "IsPlatform",
                    "Logo",
                    "Entities",
                ],
            });
            return response.applications;
        }
        catch (error) {
            return null;
        }
    }

    public async getApplicationLogo(val: string): Promise<string> {
        try {
            const response = await this.get<string>(`/${val}/logo`);
            return Promise.resolve(response);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }

    public async getApplicationsByIds(ids: string[]): Promise<ISnakeApplication[]> {
        try {
            if(ids.length > 0){
                const response = await this.post<ISnakeSearchApplications>("search",{
                    filters: {
                        snakeIds: ids,
                    },
                    fields: [
                        "IsPlatform",
                        "Logo",
                        "Entities",
                    ],
                });
                return response.applications;
            } else {
                return [];
            }
        }
        catch (error) {
            return null;
        }
    }

    public async getApplicationsByOwner(owner: string): Promise<ISnakeApplication[]> {
        try {
            const response = await this.post<ISnakeSearchApplications>("search",{
                filters: {
                    owners: [owner],
                },
                fields: [],
            });        
            return response.applications;
        }
        catch (error) {
            return null;
        }
    }

    public async getApplicationById(id: string): Promise<ISnakeApplication> {
        try {
            const response = await this.get<ISnakeApplication>(`${id}`);
            return response;
        }
        catch (error) {
            return null;
        }
    }
}

const snakeClient = new SnakeClient();

export { SnakeClient, snakeClient };
