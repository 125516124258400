import { IUserGroup, IUserGroupList, IGetUserGroupParams, IGetUserGroupSearchForm } from "@/models";
import { BaseRepository } from "@/repositories";

class UserGroupRepository extends BaseRepository<IUserGroup> {
    constructor() {
        super("/usergroups");
    }

    public async search(payload: IGetUserGroupSearchForm): Promise<IUserGroupList> {
        return await this.post<IUserGroupList>(`/search`, payload);
    }

    public async getAllWithRequestsNumber(parameters: IGetUserGroupParams): Promise<IUserGroupList> {
        return await this.get<IUserGroupList>("", {
            pageNumber: parameters.pageNumber,
            pageSize: parameters.pageSize,
            sortBy: parameters.sortBy,
            sortDesc: parameters.sortDesc
        });
    }
}

const userGroupRepository = new UserGroupRepository();

export { UserGroupRepository, userGroupRepository };
