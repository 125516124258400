import { ClientTypes } from "@/enums";
import { IUserGraph } from "@/models";
import { BaseClient } from "./BaseClient";

class GraphClient extends BaseClient {

    public constructor() {
        super("/", ClientTypes.GraphApi);
    }

    public async getCurrentUser(): Promise<IUserGraph | null> {
        try {
            const response = await this.get<IUserGraph>("/me/");

            return this.mapToUserGraph(response);
        }
        catch (error) {
            return null;
        }
    }

    public async getCurrentUserPhoto(): Promise<string> {
        try {
            const response = await this.downloadPhoto("/me/photos/48x48/$value");

            // eslint-disable-next-line id-blacklist
            const base64 = btoa(String.fromCharCode(...new Uint8Array(response.data)));

            return base64;
        }
        catch (error) {
            return "";
        }
    }

    public async getUserPhoto(uniqueId: string): Promise<string> {
        try {
            const response = await this.downloadPhoto(`/users/${uniqueId}/photo/$value`);

            // eslint-disable-next-line id-blacklist
            const base64 = btoa(String.fromCharCode(...new Uint8Array(response.data)));

            return base64;
        }
        catch (error) {
            return "";
        }
    }

    public async getFilteredUsers(prefix: string): Promise<IUserGraph[]> {
        let queryFilter = `startswith(givenName, '${prefix}')`;
        queryFilter += ` or startswith(surname,'${prefix}')`;
        queryFilter += ` or startswith(displayName,'${prefix}')`;
        queryFilter += ` or startswith(mail,'${prefix}')`;
        queryFilter += ` or startswith(userPrincipalName,'${prefix}')`;

        const graphRequest = await graphClient.get<any>("/users", {
            params: {
                "$filter": queryFilter,
            },
        });

        const users = graphRequest.value as IUserGraph[];

        return users.filter(user => user.mail).map(user => ({
            id: user.id,
            mail: user.mail,
            displayName: user.displayName,
            jobTitle: user.jobTitle,
        }));
    }

    private mapToUserGraph(userData: IUserGraph) {
        return {
            id: userData.id,
            mail: userData.mail,
            displayName: userData.displayName,
            jobTitle: userData.jobTitle,
        };
    }
}

const graphClient = new GraphClient();

export { GraphClient, graphClient };
