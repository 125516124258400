import { IGroup } from "@/models";
import { BaseRepository } from "@/repositories";

class GroupRepository extends BaseRepository<IGroup> {
    constructor() {
        super("/groups");
    }

    public async search(payload: Object): Promise<IGroup[]> {
        return await this.post<IGroup[]>(`/search`, payload);
    }

    public async searchByIds(payload: Object): Promise<IGroup[]> {
        return await this.post<IGroup[]>(`/searchByIds`, payload);
    }
}

const groupRepository = new GroupRepository();

export { GroupRepository, groupRepository };
