import { IPayGroup } from "@/models";
import { BaseRepository } from "@/repositories";

class PayGroupRepository extends BaseRepository<IPayGroup> {
    constructor() {
        super("/paygroups");
    }

    public async search(payload: Object): Promise<IPayGroup[]> {
        return await this.post<IPayGroup[]>(`/search`, payload);
    }

    public async getAllWithRequestsNumber(url?: string): Promise<IPayGroup[]> {
        return await this.get<IPayGroup[]>(url);
    }
}

const payGroupRepository = new PayGroupRepository();

export { PayGroupRepository, payGroupRepository };
